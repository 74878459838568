@use "src/_btn.scss";
@use "src/_theme.scss";



.App {
  text-align: center;
  padding: 16px;
}

strong, button, input {
  @include theme.altFont;
}

body {
  @include theme.textFont;
  background: theme.$bg;
  color: theme.$fontColor;
  width: 100%;
  height: 100vh;
  margin: 0;
}

a, a:visited {
  @include theme.gradientText(theme.$theme3, theme.$theme);
  text-decoration: none;
  &:hover {
    @include theme.gradientText(theme.$theme, theme.$theme3);
  }
}

@include btn.Btn;

h1, h2, h3 {
  @include theme.titleFont;
}

h2 {
  @include theme.gradientText(theme.$theme3, theme.$theme);
  font-size: 64px;
  display: inline-block;
}

h1.title {
  text-align: left;
  font-size: 128px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
  
}
p.subtitle {
  margin-top: -24px;
  //margin-left: 24px;
  margin-left: 144px;
  text-align: left;
  color: gray;
}
p.small {
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 10px;
}

.btn-connect {
  position: fixed;
  top: 0;
  right: 16px;
  left: calc(100vw - 320px);
  bottom: calc(100vh - 48px);
  margin: 0;
  font-size: 16px;
  transition: 0.2s top ease-in-out,
              0.2s bottom ease-in-out,
              0.2s left ease-in-out,
              0.2s right ease-in-out,
              0.1s font-size ease-in-out,
              0.1s font-weight ease-in-out,
              0.2s border-radius ease-in-out;
  border: none;
  cursor: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  img {
    height: 32px;
    width: 32px;
    vertical-align: middle;
    margin-right: 4px;
  }
  span {
    vertical-align: middle;
  }
  &:hover {
    transform: none;
    filter: none;
    border: none;
    color: white;
  }

}

.Dapp-connected--false {
  .TheDapp {
    display: none;
  }

  .btn-connect {
    $vh: 50vh;
    $vw: 50vw;
    top: max((100vh - #{$vh})/2, (100vh - #{$vw})/2);
    bottom: max((100vh - #{$vh})/2, (100vh - #{$vw})/2);
    left: max((100vw - #{$vh})/2, (100vw - #{$vw})/2);
    right: max((100vw - #{$vh})/2, (100vw - #{$vw})/2);
    border-radius: max(#{$vw},#{$vh});
    font-size: min(#{$vw}/6,#{$vh}/6);
    font-weight: 100;
    cursor: pointer;
  }
}

.token-selector {
  .token {
    height: 64px;
    padding: 8px;
    box-sizing: border-box;
    &__img {
      position: relative;
      height: 48px;
      width: 48px;
      img {
        height: 48px;
        width: 48px;
        position: absolute;
        top: 0;
        left: 0;
      }
      display: inline-block;
      border-radius: 48px;
      overflow: hidden;
      vertical-align: middle;
      margin-right: 8px;
    }
    
    span {
      vertical-align: middle;
    }
  }
}
$red: #FF4A4A;
$rorange: #FF6A4D;
$orange: #FF9052;
$yorange: #FFB655;
$yellow: #FFD757;
.routes {
  border-left: 1px solid $yellow;
  border-bottom: 1px solid $yellow;
  padding: 16px 0 0 0;
  text-align: right;
 }
 .route {
  border-top: 2px solid $yellow;
  position: relative;
  margin: 8px 0;
  cursor: pointer;
  transition: 0.2s background ease-in-out, 0.2s color ease-in-out;
  &:hover {
    color: black;
    background: $yellow;
    .route-el--last-false {
      filter: drop-shadow(0 0 4px rgba(0,0,0,0.4));
      transform: scale(1.1);
    }
  }
  span.abs {
    position: absolute;
    display: inline-block;
    font-size: 10px;
    color: gray;
    bottom: 4px;
    left: 16px;
  }
 }
 .route-el{
  
  display: inline-block;
  margin-right: 8px;
  font-size: 12px;
  line-height: 40px;
  height: 40px;
  font-weight: 600;
  strong {
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    font-weight: 100;
  }
  &--0 {
    font-size: 16px;
  }
  &--last-false {
    transition: 0.2s filter ease-in-out, 0.2s transform ease-in-out;
    font-size: 20px;
    border-bottom: 1px solid $yellow;
    filter: drop-shadow(0 0 4px rgba(0,0,0,0));
    transform: scale(1);
    padding: 0 8px;
  }



 }

 .shower, .antishower--1000 {
  display: block;
 }
 .shower--1000, .antishower {
  display: none;
 }

 .sider {
  position: fixed;
  right: 0;
  bottom: 0;
  text-align: right;
  background: radial-gradient(farthest-side at 100% 100%, theme.$theme, black);
  padding: 8px;

  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  p.small{
    font-size:12px;
    margin: 0;
  }
  p {
    margin:8px 0;
    font-size: 20px;
  }
 }






