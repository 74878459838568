

@mixin titleFont {
  //font-family: 'Patrick Hand SC', cursive;
  font-family: 'Advent Pro', sans-serif;

}

@mixin mainFont {
  font-family: 'Hepta Slab', serif;
}

@mixin buttonFont {
	font-family: "Potta One",cursive;
}

$theme: #ff0420;
$saturate: 0%;
$themeHalfSaturated: scale-color($theme, $saturation: $saturate/2);
$themeSaturated: scale-color($theme, $saturation: $saturate);

$themeComplement: adjust-hue($theme, 180deg);

$themeTetrad: adjust-hue($theme, 90deg);
$themeTetradAnti: adjust-hue($themeComplement, 90deg);

$themeTriad:adjust-hue($themeComplement, -120deg);
$themeTriadRight:adjust-hue($themeComplement, 120deg);



// $theme1: #ffe6d4; // fonts
$theme1: scale-color($theme, $lightness: 90%); // fonts

$theme2: scale-color($themeComplement, $saturation: $saturate/2); // titles n shit
$theme3: scale-color($themeTriadRight, $saturation: $saturate);;

@mixin gradientText ($a, $b) {
  background: linear-gradient(90deg, $a, $b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin gradientText3 ($a, $b, $c) {
  background: linear-gradient(90deg, $a, $b, $c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}




$bgGradientColorOne: scale-color($themeSaturated, $lightness: -100%);
$bgGradientColorTwo: scale-color($themeSaturated, $lightness: -90%);
$bgGradientColorThree: scale-color($themeSaturated, $lightness: -80%);
$bgGradientColorFour: scale-color($themeSaturated, $lightness: -100%);
/*$bg: linear-gradient(
	135deg, 
	$bgGradientColorOne, 
	$bgGradientColorTwo, 
	$bgGradientColorThree, 
	$bgGradientColorFour);
	*/
	$bg: black;

$itembgGradientColorOne: scale-color($theme, $lightness: 0%);
$itembgGradientColorTwo: scale-color($theme, $lightness: -20%);
$itembgGradientColorThree: scale-color($theme, $lightness: -40%);
$itembgGradientColorFour: scale-color($theme, $lightness: -60%);

/*$itemBG: linear-gradient(
	-45deg, 
	$itembgGradientColorOne, 
	$itembgGradientColorTwo, 
	$itembgGradientColorThree, 
	$itembgGradientColorFour); 
	*/
$itemBG: rgba($theme, 0.8);

@mixin titleFontColor {
	color: $theme1;
}

@mixin poolTitleFontColor {
	//@include gradientText($themeComplement, $theme1);
	color: $theme1;
	filter: drop-shadow(2px 2px 2px black);
}
$fontColor: white;
$specialFontColor: $theme2;

$borderColor: $theme1;

$effectColor: $themeComplement;

$buttonBG: linear-gradient(135deg, $themeSaturated, $theme3);
$buttonColor: $theme1;

$buttonBgHOVER: linear-gradient(135deg, $theme3, $themeSaturated);
$buttonColorHOVER: $theme1;

$buttonBorderHOVER: $buttonColorHOVER;

$pink: #ff00b2ff;
$blue: #0000b2ff;
$green: #00ffb2ff;


@mixin gradGP {
	@include gradientText($green,$pink);
}
@mixin gradPG {
	@include gradientText($pink,$green);
}
@mixin gradGB {
	@include gradientText($green,$blue);
}
@mixin gradBG {
	@include gradientText($blue,$green);
}
@mixin gradBP {
	@include gradientText($blue,$pink);
}
@mixin gradPB {
	@include gradientText($pink,$blue);
}

@mixin titleFont {
  font-family: 'Zen Tokyo Zoo', cursive;
}

@mixin textFont {
  font-family: 'Lexend Exa', sans-serif;
}
@mixin altFont {
  font-family: 'Inter', sans-serif;
}

@mixin sgrad($a) {
	background: linear-gradient(90deg, $a, scale-color($a, $lightness: 20%));
}
@mixin dgrad($a,$b) {
	background: linear-gradient(90deg, $a, $b);
}
